export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Shengya Sports',
    description:
      'AI sports prediction assistant	Football, sports, events, results, match status, event inquiries, sports news',
    buttonLabel: '下载！',
    imgStart: '',
    img: require('../../images/shengya.jpg'),
    alt: '信用卡',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '关键时刻',
    description:
      "通过实时体育新闻和事件更新，保持紧跟比赛，确保您捕捉到每一个关键时刻。",
    buttonLabel: '了解更多',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '主要特点',
    headline:
      '详情',
    description:
      "【Intelligence Insight】Wonderful touch, complete mastery",
    description2:
      "【Professional Support】Sports Decision-making Smart Consulting",
    description3:
      "【Personalized Guidance】Q&A Interaction Precise Guidance",
    description4:
      "【Comprehensive data】 Comprehensive schedule available at one touch",
    description5:
      "【Efficient interaction】Extremely fast interaction and comprehensive experience",
    // buttonLabel: '查看详情',
    imgStart: 'start',
    img: require('../../images/shengya.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '主要特点',
    headline: '多元化',
    description:
      '涵盖各种运动和联赛 – 足球、篮球、网球、棒球等。',
    buttonLabel: '立即注册',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '个性化推荐',
    description:
      "定制体验  – 根据您的兴趣提供个性化推荐。",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '终极智能伴侣',
    headline:
      'DribblePro',
    description:
      "对于体育爱好者、事件迷和渴望即时事件更新的任何人来说，无论是最新的比赛进展还是即时事件结果，DribblePro都是您的终极智能伴侣",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img5.png'),
    alt: 'Vault',
    start: 'true'
  };